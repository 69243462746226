import { useState } from 'react'
import { Routes, Route } from "react-router-dom"
import reactLogo from './assets/react.svg'
import './App.css'

import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ProtectedRoutes from './components/ProtectedRoutes';
import Main from './components/Main';
import Teams from './components/Teams';
import Profile from './components/Account/Profile';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<ProtectedRoutes />}>
          <Route index element={<Main />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
